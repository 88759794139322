import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { environment } from '@env/environment';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgOptimizedImage],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'snipocc-predict-ui';
  protected deployTimeStamp: String = environment.DeployTimeStamp;

  constructor(protected authenticator: AuthenticatorService, protected router: Router) {
  }

  protected signOut() {
    this.authenticator.signOut();
    void this.router.navigateByUrl('/login');
  }
}

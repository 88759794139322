import { RedirectCommand, Router, Routes } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

export const authGuard = () => {
  const auth = inject(AuthenticatorService);
  const router = inject(Router);
  console.log("auth status", auth.authStatus)
  if (auth.authStatus !== 'authenticated') {
    const loginPath = router.parseUrl('/login');
    return new RedirectCommand(loginPath);
  }
  return true;
};

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./features/login/login-form/login-form.component'),
  },
  {
    path: 'upload',
    loadComponent: () => import('./features/upload/upload-form/upload-form.component'),
    canActivate: [authGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'upload'
  },
  {
    path: '**',
    redirectTo: '',
  },
];

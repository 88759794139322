import { ApplicationConfig, importProvidersFrom, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, RouterFeatures, withDebugTracing, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { environment } from '@env/environment';
import { provideHttpClient, withFetch } from '@angular/common/http';

const routerFeatures: RouterFeatures[] = [
  withViewTransitions({ skipInitialTransition: true }),
];

if (!environment.production) {
  routerFeatures.push(withDebugTracing());
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, ...routerFeatures),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    importProvidersFrom(AmplifyAuthenticatorModule),
  ],
};

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { Amplify } from 'aws-amplify';
import { environment } from '@env/environment';
import { enableProdMode } from '@angular/core';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-angular';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.userPoolId,
      userPoolClientId: environment.userPoolClientId,
      identityPoolId: environment.identityPoolId,
      loginWith: {
        username: true,
      },
      allowGuestAccess: false,
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
});

I18n.putVocabulariesForLanguage('de', translations['de']);
I18n.putVocabulariesForLanguage('en', translations['en']);
I18n.setLanguage((navigator.languages.find((lang) => lang.includes('en') || lang.includes('de')) ?? 'en').split('-')[0]);

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

<div class="sticky top-0 navbar bg-base-100">
  <div class="flex gap-2">
    <img ngSrc="CA_App_Icon-01.png" [width]="64" [height]="64" alt="castalytics logo" priority class="filter backdrop-opacity-100 dark:filter-none">
    <div class="w-[64px] h-[64px] rounded-lg p-1 dark:bg-white grid place-items-center">
      <img ngSrc="fassisi-logo.png" [width]="64" [height]="64" alt="fassisi logo" priority>
    </div>
  </div>
  <div class="flex-1"></div>
  @if (authenticator.authStatus === "authenticated") {
    <div class="flex-none">
      <div class="dropdown dropdown-end">
        <button tabindex="0" role="button" class="btn btn-square rounded-lg btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block h-8 w-8 stroke-current">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        <ul
          tabindex="0"
          class="menu dropdown-content bg-base-100 rounded-lg z-[1] w-32 p-1 shadow">
          <li><a (click)="signOut()">Sign Out</a></li>
        </ul>
      </div>
    </div>
  }
</div>
<div class="flex w-full h-full flex-1 max-h-[80vh] max-w-screen-2xl place-self-center">
  <router-outlet class="h-0"/>
</div>
<footer class="fixed bottom-0 footer footer-center p-4 landscape:hidden">
  <aside>
    <p>Build at: {{deployTimeStamp}}</p>
  </aside>
</footer>
